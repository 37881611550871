import PinnedWidgets from "pages/Dashboard/pinnedWidgets/PinnedWidgets";
import { FiCheck, FiPlus } from "react-icons/fi";
import { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import classNames from "utilities/ClassNames";
import { Loader } from "processhub-components-lib";
import AllDynamicWidgets from "pages/Dashboard/AllDynamicWidgets";
import useApp from "hooks/useApp";
import { usePins } from "hooks/usePins";
import { TitleInit, TitleInputInit, editTitleInit } from "./config/constants";
import { cn } from "@/lib/utils";

export default function NewDashboard() {
  const {
    updateDashboardWidgets,
    dashboardWidgets,
    dashboardWidgetsLoading,
  } = useApp();

  const { unpinAllWidgets, loading } = usePins();
  const [titles, setTitles] = useState<any>(TitleInit);
  const [editTitle, setEditTitle] = useState(editTitleInit);
  const [titleInput, setTitleInput] = useState(TitleInputInit);
  const [unpinConfirmation, setUnpinConfirmation] = useState(false);

  useEffect(() => {
    const temptitles = [...titles];
    const widgetTitles = [
      dashboardWidgets.title1,
      dashboardWidgets.title2,
      dashboardWidgets.title3,
    ];
    widgetTitles.map((item: any, index: any) => {
      if (item && item !== "") {
        temptitles[index] = { ...temptitles[index], title: item };
      }
    });

    setTitles(temptitles);
  }, [dashboardWidgets]);

  if (dashboardWidgetsLoading) {
    return (
      <div className="w-full h-[80vh] grid place-content-center">
        <Loader />
      </div>
    );
  }

  const handleTitleChanges = (
    index: any,
    newValue: any,
    item: any,
    setitem: any
  ) => {
    const updatedTitleInputs = [...item];
    updatedTitleInputs[index] = {
      ...updatedTitleInputs[index],
      title: newValue,
    };
    setitem(updatedTitleInputs);
  };

  return (
    <div
      className={classNames(
        "overflow-y-scroll xl:overflow-y-hidden", "flex-row-reverse pb-3 w-full h-full xl:h-[91vh] 2xl:h-[95vh] overflow-hidden flex relative"
      )}
    >
      {dashboardWidgets.selectedType !== "init" && (
        <div
          className={classNames(
            "lg:min-w-[260px]  2xl:min-w-[260px] flex flex-wrap justify-between items-baseline relative",
            "w-[17%]"
          )}
        >
          <PinnedWidgets />
          <div className="flex items-end justify-end h-max w-full absolute bottom-0 2xl:bottom-5 right-4">
            {!unpinConfirmation && <button className="btn-danger" onClick={() => setUnpinConfirmation(true)}>Unpin All Tags</button>}
            {unpinConfirmation &&
              <div className="flex gap-5">
                <button className="btn-sec" onClick={() => setUnpinConfirmation(false)}>Keep</button>
                <button className="btn-danger" onClick={() => {
                  unpinAllWidgets(1);
                  setUnpinConfirmation(false);
                }}>Remove</button>
              </div>}
          </div>
        </div>
      )}
      <div
        className={classNames(
          dashboardWidgets.selectedType === "init" ? "w-full" : "w-[83%]"
        )}
      >
        {dashboardWidgets.selectedType === "init" && (
          <div className="flex flex-col gap-7 w-full justify-center items-center pt-24 2xl:pt-40">
            <h1 className="font-bold xl:text-2xl 2xl:text-3xl">Select Dashboard type</h1>
            <div className="w-full h-full flex gap-3 justify-center flex-wrap">
              {editTitle.map((input: any, index: any) => (
                <div key={index} className="flex flex-col gap-3">
                  {input.title && (
                    <div className="flex gap-2 items-center">
                      <input
                        className="input"
                        value={titleInput[index].title}
                        onChange={(e: any) =>
                          handleTitleChanges(
                            index,
                            e.target.value,
                            titleInput,
                            setTitleInput
                          )
                        }
                      />
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          handleTitleChanges(
                            index,
                            titleInput[index].title,
                            titles,
                            setTitles
                          );
                          handleTitleChanges(
                            index,
                            false,
                            editTitle,
                            setEditTitle
                          );
                          updateDashboardWidgets({
                            ...dashboardWidgets,
                            [`title${index + 1}`]: titleInput[index].title,
                          });
                        }}
                      >
                        <FiCheck size={20} />
                      </div>
                    </div>
                  )}
                  {!input.title && (
                    <div className="flex gap-2 items-center">
                      <div
                        className="cursor-pointer"
                        onClick={() =>
                          handleTitleChanges(
                            index,
                            true,
                            editTitle,
                            setEditTitle
                          )
                        }
                      >
                        <BiEditAlt size={20} />
                      </div>
                      <h1 className="font-bold xl:text-xl 2xl:text-2xl text-center">
                        {titles[index].title}
                      </h1>
                    </div>
                  )}
                  <div className="bg-black rounded-md">
                    <div
                      onClick={() =>
                        updateDashboardWidgets({
                          ...dashboardWidgets,
                          selectedType: index + 1,
                        })
                      }
                      className={cn("lg:min-w-[300px] xl:min-w-[400px] xl-2xl:min-w-[450px] 2xl:min-w-[500px]", "grid grid-cols-3 grid-rows-2 p-3 gap-3 bg-dark rounded-md cursor-pointer hover:border-green border-2 border-transparent duration-300")}
                    >
                      {[0, 1, 2, 3, 4].map((item: any) => (
                        <div
                          key={item}
                          className={classNames(
                            (index === 0 && item === 0) ||
                              (index === 1 && item === 4)
                              ? "col-span-2"
                              : "",
                            index === 2 ? "bg-back" : "gradient",
                            "p-0.5 rounded-md"
                          )}
                        >
                          {(index === 0 || index === 1) && (
                            <div className={cn("lg:h-16 xl:h-24 xl-2xl:h-28 2xl:h-32", "bg-dark w-full rounded-md")}></div>
                          )}
                          {index === 2 && (
                            <div className={cn("lg:h-16 xl:h-24 xl-2xl:h-28 2xl:h-32", "bg-black w-full rounded-md grid place-content-center")}>
                              <FiPlus />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {dashboardWidgets.selectedType && (
          <AllDynamicWidgets type={dashboardWidgets.selectedType} />
        )}
      </div>
    </div>
  );
}
