import { useEffect } from "react";
import { ErrorPage } from "processhub-components-lib";

const LoginErrorPage = () => {

  return (
    <ErrorPage>
      <div></div>
      {/* <ProgressMonitoringLogo variant="regular" /> */}
    </ErrorPage>
  );
};

export default LoginErrorPage;