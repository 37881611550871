import useApp from "./useApp";
import useData from "./useData";
import { usePins } from "./usePins";
import useScreen from "./useScreen";
import useSignalR from "./useSignalR";
import useUnity from "./UseUnity";

const allHooks = {
  useApp,
  useData,
  useScreen,
  useSignalR,
  useCoordinates: null,
  useUnity,
  usePins
};

export default allHooks;