import { Loader } from "processhub-components-lib";
import { useEffect, useMemo, useState } from "react";
import { FiRefreshCw, FiTrash2 } from "react-icons/fi";
import { getAllHealth, refreshHealth } from "api/health";
export default function HealthContainer() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setdata] = useState<any>([]);
  const getAllHealthData = async () => {
    const response: any = await getAllHealth();
    if (response && response.status === 200) {
      console.log(response.data);
      setdata(response.data);
    }
    setLoading(false);
  };
  const refreshHealthwithType = async (type: any) => {
    const response: any = await refreshHealth(type);
    if (response && response.status === 200) {
      if (data.length) {
        const tempData = data.filter((val: any) => val.id !== type);
        const respData = response.data;
        const DataToBeRefreshed = data.filter(
          (val: any) => val.id === respData.type
        )[0];
        DataToBeRefreshed.message = respData.message;
        DataToBeRefreshed.id = respData.type;
        DataToBeRefreshed.status = respData.status;
        const curr = new Date();
        DataToBeRefreshed.lastCheckedOnUTC = curr;
        const updatedData = [...tempData, DataToBeRefreshed];
        setdata(updatedData);
      }
    }
  };
  useEffect(() => {
    getAllHealthData();
  }, []);
  const getstatusLabel = (statusId: any) => {
    const statusLabel = [
      { value: 0, label: "Unknown" },
      { value: 1, label: "Healthy" },
      { value: 2, label: "Warning" },
      { value: 3, label: "Failed" },
    ];
    return statusLabel.find((status) => {
      return status.value === statusId;
    })?.label;
  };
  const getDateformat = (id: any) => {
    const val = data.filter((obj: any) => obj.id === id);
    if (val.length) {
      const d = new Date(val[0].lastCheckedOnUTC);
      return d.toLocaleString();
    }
  };
  const getstatuscolor = (status: any, ismessage: any) => {
    let color: any = "";
    switch (status) {
      case 1:
        color = ismessage ? "skyblue" : "limegreen";
        break;
      case 2:
        color = "orange";
        break;
      case 3:
        color = "crimson";
        break;
    }
    return color;
  };
  return (
    <>
      {loading && (
        <div className="w-full h-[80vh] grid place-content-center">
          <Loader />
        </div>
      )}
      {data && !loading && (
        <div className="pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll h-full">
          <div className="gradient p-0.5 rounded-md mt-3">
            <div className="p-3 rounded-md bg-black h-full max-h-[650px] overflow-scroll">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="text-green">
                    <th className="text-left">Name</th>
                    <th className="text-left">Description</th>
                    <th className="text-left">Status</th>
                    <th className="text-left">Message</th>
                    <th className="text-left">Last Updated</th>
                    <th className="text-left">Retry</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((user: any) => (
                    <tr key={user.id} className="border-b border-b-lightGray">
                      <td>{user.name}</td>
                      <td>{user.desc}</td>
                      <td style={{ color: getstatuscolor(user.status, false) }}>
                        {getstatusLabel(user.status)}
                      </td>
                      <td style={{ color: getstatuscolor(user.status, true) }}>
                        {user.message}
                      </td>
                      <td>{getDateformat(user.id)}</td>
                      <td>
                        <FiRefreshCw
                          size={20}
                          className="mt-1.5 cursor-pointer"
                          color="cyan"
                          onClick={() => {
                            refreshHealthwithType(user.id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}