import { Bar } from "processhub-components-lib";
import useScreen from "hooks/useScreen";
import { usePins } from "hooks/usePins";
import { FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";

export default function Pin({ data, unityPin }: any) {
  const { unPin } = usePins();
  const { isScaled } = useScreen();
  const metaData = data.data[0];
  const [pinToRemove, setPinToRemove] = useState<number | null>(null);
  const [deletionConfirmed, setDeletionConfirmed] = useState(false);

  useEffect(() => {
    if (deletionConfirmed) {
      unPin(pinToRemove, null, unityPin ? 2 : 1);
      setDeletionConfirmed(false);
    }
  }, [deletionConfirmed]);

  return (
    <div
      className={classNames(
        "h-full p-0.5 rounded-md relative gradient 2xl:w-full 2xl:min-w-[150px] flex items-center"
      )}
    >
      <div className={cn("w-full rounded-md h-full bg-black px-2 flex flex-col items-center pt-1")}>
        <div className="flex justify-between w-full">
          <div className="leading-3">
            <span className="text-sm 3xl:text-lg">{metaData.tagNameIdentifier}</span>
          </div>
          <div
            className={cn("ml-auto flex cursor-pointer")}
            onClick={() => setPinToRemove(metaData.id)}
          >
            <FiX size={isScaled ? 16 : 20} />
          </div>
        </div>
        <Bar meta={metaData} barType={"pin"} />
      </div>
      {pinToRemove !== null && <div className="absolute w-full h-full bg-dark bg-opacity-75 flex items-center justify-center" >
        <div className="flex gap-5">
          <button className="btn-sec" onClick={() => setPinToRemove(null)}>Keep</button>
          <button className="btn-danger" onClick={() => setDeletionConfirmed(true)}>Remove</button>
        </div>
      </div>}
    </div>
  );
}
