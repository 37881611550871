// import { addProject, getAllProjects, getAllTasks } from "api/projectPlan";
import fileUploaderApiConfig from "config/fileUploaderApiConfig";

const ganttModalApiConfig = {
  addProject: () => null,
  getAllProjects: () => null,
  getAllTasks: () => null,
  fileUploaderApiConfig
};

export default ganttModalApiConfig;