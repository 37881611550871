import logo from "assets/logo.svg";
import { useState } from "react";
import { FiArrowLeft, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { SideMenu, UserMenu, DateTime, Info } from "processhub-components-lib";
import useScreen from "hooks/useScreen";
import classNames from "utilities/ClassNames";
import { ReactComponent as HealthsensorIcon } from "assets/healthsensorIcon.svg";
import { useNavigate } from "react-router-dom";
import allHooks from "hooks";
import { navLinks } from "./config/navLinksConfig";
import { profileConfig } from "./config/userMenuConfig";
import { useAuth } from "react-oidc-context";

export default function Header() {
  const [showSideMenu, setShowMenu] = useState(false);
  const { isScaled } = useScreen();
  const navigate = useNavigate();
  const auth = useAuth();
  const logout = async () => auth.signoutRedirect();

  return (
    <div className="w-full bg-dark relative z-40">
      <div
        className={classNames(
          "lg:h-12 xl-2xl:h-14 2xl:h-16",
          "mx-auto flex items-center px-3"
        )}
      >
        <div className="relative z-50 flex items-center gap-5">
          <div
            onClick={() => setShowMenu(!showSideMenu)}
            className="cursor-pointer duration-300"
          >
            {!showSideMenu && <FiMenu size={"30"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
            {showSideMenu && <FiArrowLeft size={"30"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
          </div>
          <Link to="/">
            <img
              src={logo}
              className={classNames("lg:h-6 xl-2xl:h-7 2xl:h-8")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="flex-1" />
        <div className="flex items-center">
          <DateTime hooks={allHooks} />
          <div className="hover:text-green cursor-pointer w-min mr-6" onClick={() => navigate('/health')}>
            <HealthsensorIcon className={classNames(isScaled ? "h-[19px] w-[19px]" : "h-[23px] w-[23px] border-white rounded-full hover:text-green")} />
          </div>
          <div className="w-max">
            <Info hooks={allHooks} />
          </div>
          <UserMenu hooks={allHooks} profileConfig={profileConfig} logout={logout} />
          <SideMenu
            closeMenu={() => setShowMenu(false)}
            showSideMenu={showSideMenu}
            navLinks={navLinks}
            hooks={allHooks}
          />
        </div>
      </div>
    </div>
  );
}
