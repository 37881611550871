// import { modifyPointCloud } from "api/timeline";
// import { getPointCloudData } from "pages/unity/domain/getPointCloudData";
import colorDropdownConfig from "./colorDropdownConfig";

const projectDateConfig = {
  getPointCloudData: () => null,
  modifyPointCloud: () => null,
  colorDropdownConfig
};

export default projectDateConfig;