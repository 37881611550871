import { acceptAllAlarm } from "api/alarm";

const acknowledgeAllAlarmsService = async () => {
  const response: any = await acceptAllAlarm();
  if (response.status === 200 || response.status === 204) {
    return { message: "success" };
  }
  else {
    return { message: "failure" };
  }
};

export default acknowledgeAllAlarmsService;